import React from "react";
import { combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";
import LoginReducer from '../../../../src/login/Reducer.js';
import MenuReducer from '../../../../src/menu/Reducer.js';
import MessageReducer from '../../../../src/message/Reducer.js';
import NoticeReducer from '../../../../src/notice/Reducer.js';
import ConfirmReducer from '../../../../src/confirm/Reducer.js';
import ContractSelectReducer from '../../../../src/contractSelect/Reducer.js';
import ConstructionSiteSelectReducer from '../../../../src/constructionSiteSelect/Reducer.js';
import TruckSelectReducer from '../../../../src/truckSelect/Reducer.js';
import ChatReducer from '../../../../src/chat/Reducer.js';
import Login from '../../../../src/login/Login.js';
import Logout from '../../../../src/login/Logout.js';
import MacadamReducer from "../macadam/Reducer";

import App from "./Application.js";
import MacadamNew from "../macadam/MacadamNew.js";
import Trucks from "../truck/Trucks.js";
import TruckNew from "../truck/TruckNew.js";
import TruckEdit from "../truck/TruckEdit.js";
import config from "../../settings/config";

export const store = createStore(
  combineReducers({
    login: LoginReducer,
    menu: MenuReducer,
    message: MessageReducer,
    notice: NoticeReducer,
    confirm: ConfirmReducer,
    macadam: MacadamReducer,
    constructionSiteSelect: ConstructionSiteSelectReducer,
    contractSelect: ContractSelectReducer,
    truckSelect: TruckSelectReducer,
    chat: ChatReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const AppWithLocation = ({ store }) => {
  const location = useLocation();

  return <App store={store} location={location} />;
};

const TruckEditWithParams = ({ store }) => {
  const params = useParams();

  return <TruckEdit store={store} params={params} />;
};

const Root = () => (
  <Provider store={store}>
    <span id="timer" />
    <BrowserRouter>
      <AppWithLocation store={store} />
      <Routes id={config.reptail ? "reptail" : ""}>
        <Route path="/login" element={<Login store={store} /> } />
        <Route path="/logout" element={<Logout store={store} /> } />
        <Route path="/macadam" element={<MacadamNew store={store} /> } />
        <Route exact path="/trucks" element={<Trucks store={store} /> } />
        <Route path="/trucks/new" element={<TruckNew store={store} /> } />
        <Route path="/truck/:id" element={<TruckEditWithParams store={store} /> } />
        <Route path="/" element={<></> } />
      </Routes>
    </BrowserRouter>
  </Provider>
);

export default Root;
