export const clearMacadams = () => ({
  type: 'CLEAR_MACADAMS'
});

export const removeMacadam = macadam => ({
  type: 'REMOVE_MACADAM',
  macadam
});

export const addMacadam = macadam => ({
  type: 'ADD_MACADAM',
  macadam
});

export const addMacadams = macadams => ({
  type: 'ADD_MACADAMS',
  macadams
});

export const changeMacadam = macadam => ({
  type: 'CHANGE_MACADAM',
  macadam
});

export const updateLastPath = (id, path) => ({
  type: 'UPDATE_LAST_PATH',
  macadamId: id,
  path: path,
});

export const addPathToLastMacadam = path => ({
  type: 'ADD_PATH_TO_LAST_MACADAM',
  path: path,
});

export const removeLastPath = macadamId => ({
  type: 'REMOVE_LAST_PATH',
  macadamId: macadamId,
});
