import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { login } from '../../../../src/login/Actions';
import { addMenuItems } from '../../../../src/menu/Actions';
import Confirm from '../../../../src/confirm/Confirm';
import Message from '../../../../src/message/Message';
import Notice from '../../../../src/notice/Notice';
import Menu from '../../../../src/menu/MenuComplete';
import Watermark from '../../../../src/watermark/Watermark';
import config from '../../settings/config';

const menuList = [
  {url: '/macadam', header: 'Murskeen lisäys'},
  {url: '/trucks', header: 'Rekat'}
];

class App extends React.Component {

  constructor(props) {
    super(props);

    localStorage.loadingSuccess = true;
    this.props.addMenuItems(menuList);

    this.path = this.props.location.pathname;
    this.login = false;
  }

  render() {
    if (this.props.user == null) {
      if (!this.login) {
        this.login = true;
        return <Navigate to='/login' push/>;
      }
    }
    else {
      /**
      if (!this.props.user.get('permissions').includes('Mass')) {
        this.props.login(null);
        this.props.showMessage('Virhe', 'Sinulla ei ole oikeuksia tähän ohjelmaan', 'Error');
        return null;
      }
      */

      if (!this.props.location.pathname.includes('/macadam') &&
          !this.props.location.pathname.match('/target/[1-9]d*') &&
          !this.props.location.pathname.includes('/trucks') &&
          !this.props.location.pathname.includes('/trucks/new') &&
          !this.props.location.pathname.match('/truck/[1-9]d*')) {
        if (this.path !== this.props.location.pathname) {
          return <Navigate to={this.path} push/>;
        }

        return <Navigate to='/macadam' push/>;
      }
    }

    return (
      <div>
        { this.props.user != null ? <Menu store={this.props.store} /> : null }
        <Message store={this.props.store} />
        <Notice store={this.props.store} />
        <Confirm store={this.props.store} />
        { config.reptail ? <Watermark/> : null }
      </div>
    );
  }
}


export default connect(state => ({
  user: state.login.get('user'),
}), { login, addMenuItems })(App);
