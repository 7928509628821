import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import { useMap, MapContainer, Marker, Popup, TileLayer, Polyline, useMapEvent } from 'react-leaflet';
import { selectTruck } from '../../../../src/truckSelect/Actions.js';
import { showNotice } from '../../../../src/notice/Actions.js';
import { showConfirm } from '../../../../src/confirm/Actions.js';
import MapTiles from '../../../../src/mapTiles/MapTiles.js';
import TruckSelect from '../../../../src/truckSelect/TruckSelect.js';
import { fetch, paddedNumber, stateValueParser, toETRSTM35FIN } from '../utils.js';
import 'leaflet/dist/leaflet.css';

export const RoadwaySelect = props => (
  <div>
    <label>Ajorata</label>
    { props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null }
    <label className='radio-container'>
      0
      <input type='radio' name='roadway' value='0'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 0}/>
      <span className="checkmark"></span>
    </label>
    <label className='radio-container'>
      1
      <input type='radio' name='roadway' value='1'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 1}/>
      <span className="checkmark"></span>
    </label>
    <label className='radio-container'>
      2
      <input type='radio' name='roadway' value='2'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 2}/>
      <span className="checkmark"></span>
    </label>
  </div>
);

export const DirectionSelect = props => (
  <div>
    <label>Suunta</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio-container'>
      1
      <input id='direction1' type='radio' name='direction' value='1'
        onClick={props.onChange.bind(this, 'direction', 'integer', null)}
        defaultChecked={props.direction === 1}/>
      <span className="checkmark"></span>
    </label>
    <label className='radio-container'>
      2
      <input id='direction2' type='radio' name='direction' value='2'
        onClick={props.onChange.bind(this, 'direction', 'integer', null)}
        defaultChecked={props.direction === 2}/>
      <span className="checkmark"></span>
    </label>
  </div>
);

export const LaneSelect = props => (
  <div>
    <label>Kaista</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio-container'>
      1
      <input type='radio' name='line' value='1'
        onClick={props.onChange.bind(this, 'lane', 'integer', null)} 
        defaultChecked={props.lane === 1}/>
      <span className="checkmark"></span>
    </label>
    <label className='radio-container'>
      2
      <input type='radio' name='line' value='2'
        onClick={props.onChange.bind(this, 'lane', 'integer', null)} 
        defaultChecked={props.lane === 2}/>
      <span className="checkmark"></span>
    </label>
  </div>
);

export const AccuracyFixer = props => {
  if (!props.show) return null;
  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='accuracy-modal'>
        <h4>Korjataan sijaintia epätarkkuuden takia...</h4>
        <h5>Tämän hetkinen tarkkuus: {props.accuracy} m</h5>
        <div className='loader'/>
        <br/>
        <button onClick={props.submit}>
          Hyväksy silti
        </button>
        <button onClick={props.toggle}>
          Syötä manuaalisesti
        </button>
      </div>
    </div>
  );
}

class ChangeMacadamView extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      height: 0,
      multiplier: 0,
      truckMass: null,
      attentions: null,
      confirmedRoad: false
    };

    this.changeState = this.changeState.bind(this);
    this.doChangeMacadam = this.doChangeMacadam.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.macadam == null) return;

    if (nextProps.selectedTruck != null && this.props.selectedTruck !== nextProps.selectedTruck) {
      this.setState({
        truckRegisterNumber: nextProps.selectedTruck.get('register_number'),
      });
    }

    if (nextProps.macadam === this.props.macadam) return;

    this.props.selectTruck(nextProps.macadam.get('truck'));

    this.setState({
      height: nextProps.macadam.get('height'),
      multiplier: nextProps.macadam.get('multiplier'),
      truckMass: nextProps.macadam.get('truck_mass'),
      attentions: nextProps.macadam.get('attentions')
    });
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }
    
    this.setState({[propertyName]: value});
  }

  async doChangeMacadam() {
    const height = parseFloat(this.state.height);
    if (height === 0) {
      this.props.showNotice('Korkeus ei voi olla 0', 'Warning')
      return;
    }

    const multiplier = parseFloat(this.state.multiplier);
    if (multiplier === 0) {
      this.props.showNotice('Kerroin ei voi olla 0', 'Warning')
      return;
    }

    if (this.props.selectedTruck == null) {
      this.props.showNotice('Rekkaa ei ole valittu', 'Warning');
      return;
    }

    const truckMass = parseFloat(this.state.truckMass);
    if (truckMass === 0) {
      this.props.showNotice('Kuorman massaa ei ole annettu', 'Warning');
      return;
    }

    let macadam = this.props.macadam;
    macadam = macadam.set('height', height);
    macadam = macadam.set('multiplier', multiplier);
    macadam = macadam.set('truckId', this.props.selectedTruck.get('id'));
    macadam = macadam.set('truck', null);
    macadam = macadam.set('truck_mass', truckMass);
    macadam = macadam.set('attentions', this.state.attentions);

    if (macadam.get('not_saved')) {
      this.props.changeLocalMacadam(macadam);
    }
    else {
      this.props.changeMacadam(macadam);
    }
  }

  render() {
    if (this.props.macadam == null) return null;

    return (
      <div onClick={this.props.clear} className='modal'>
        <div onClick={e => e.stopPropagation()} id='load-data-modal'>
          <h3 className='center'>Murskeen muokkaus</h3>
          <div className='row'>
            <div className="column">
              <label htmlFor='height'>Korkeus</label>
              <input id='height' type='tel'
                    onChange={this.changeState.bind(this, 'height', 'float', 0.0)} value={this.state.height} required/>
            </div>
            <div className="column">
              <label htmlFor='multiplier'>Kerroin</label>
              <input id='multiplier' type='tel'
                    onChange={this.changeState.bind(this, 'multiplier', 'float', 0.0)} value={this.state.multiplier} required/>
            </div>
          </div>
          <div className='row'>
            <div className='column'>
              <label htmlFor='truck'>Rekka</label>
                <TruckSelect required/>
            </div>
            <div className='column'>
              <label htmlFor='mass'>Kuorma (Tonnit) </label>
                <input id='mass' type='tel'
                        value={this.state.truckMass}
                        onChange={this.changeState.bind(this, 'truckMass', 'float', 0.0)} required/>
            </div>
            <div className='column'>
              <label htmlFor='attentions'>Huomiot</label>
              <input id='attentions' type='text' value={this.state.attentions || ''}
                    onChange={this.changeState.bind(this, 'attentions', 'string', '')}/>
            </div>
          </div>
          <div className='row'>
            <div className='column'>
              <button onClick={this.doChangeMacadam}>
                Muokkaa
              </button>
            </div>
            <div className='column'>
              <button className='button-outline' onClick={this.props.clear}>
                Peruuta
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  selectedTruck: state.truckSelect.get('selectedTruck'),
  trucks: state.truckSelect.get('trucks'),
}), { selectTruck, showNotice, showConfirm })(ChangeMacadamView);


export const MapView = (properties) => {

  const props = properties;

  let map;
  let trucks = [];
  let position = [64.1, 26.5];
  let zoom = 6;
  let truckUpdateInterval = null;

  const [showedWarning, setShowedWarning] = useState(false);
  const [directions, setDirections] = useState([1, 2]);
  const [trucksState, setTrucksState] = useState([]);
  const [lines, setLines] = useState([]);
  const [height, setHeight] = useState(window.innerHeight);
  const [mapTilesUrl, setMapTilesUrl] = useState('');
  const [mapTilesAttribution, setMapTilesAttribution] = useState('');
  const [maxZoom, setMaxZoom] = useState(16);
  const [selectedLine, setSelectedLine] = useState();

  useEffect(() => {
    if (props.mapPosition) {
      position = props.mapPosition;
      zoom = props.mapZoom;
    }

    update(props);
    getTrucks();
    truckUpdateInterval = setInterval(getTrucks, 5000);
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
      clearInterval(truckUpdateInterval);
    };
  }, []);

  const updateDimensions = () => {
    const height = window.innerHeight;
    setHeight(height);
  }

  const update = async (props) => {
    if (props.mapPaths.length === 0) {
      setLines([]);
      return;
    }

    let lines = [];

    for (let i = 0; i < props.macadams.size; i++) {
      const macadam = props.macadams.get(i);

      for (let p = 0; p < macadam.get('paths').size; p++) {
        const path = macadam.get('paths').get(p);

        if (!(directions.includes(path.get('direction'))) || !path.get('start_latitude')) continue;

        const date = new Date(path.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
          + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

        let color;

        if (selectedLine === path.get('id')) {
          color = '#00FFFF';
        }
        else if (path.get('direction') === 1) {
          color = '#0000FF';
        }
        else {
          color = '#00FF00';
        }

        const text = (
          <span>
            {time}
            <br />
            {!props.REPTAIL ?
              <span>
                Aloitus: {path.get('road')} / Osa: {path.get('start_part')} / Paalu: {path.get('start_distance')}
                <br />
                Lopetus: {path.get('road')} / Osa: {path.get('end_part') || '- '} / Paalu: {path.get('end_distance') || '-'}
              </span>
              : null
            }
            <br />
            Ajorata: {path.get('roadway')}
            <br />
            Suunta: {path.get('direction')}
            <br />
            Kaista: {path.get('lane')}
            <br />
            Rekka: {macadam.get('truck').get('register_number') ? macadam.get('truck').get('register_number') : '-'}
            <br />
            {macadam.get('paths').size > 1 ?
              <div>
                Arvio käytetystä massasta: {macadam.get('truck_mass') ?
                  Math.round(macadam.get('truck_mass') / macadam.get('paths').size * 100) / 100
                  : '-'}
              </div>
              : null
            }
            Kuorman koko: {macadam.get('truck_mass') ? Math.round(macadam.get('truck_mass') * 100) / 100 : '-'}
            <br />
            Pituus: {path.get('length') + ' m'}
          </span>
        );

        const mapPath = props.mapPaths[path.get('id')];

        if (mapPath.length === 1) {
          mapPath.push(mapPath[0]);
        }

        lines.push(
          <Polyline key={path.get('id')}
            positions={mapPath}
            weight={10}
            opacity={0.6}
            color={color}
            onClick={selectLine.bind(null, path.get('id'))}
          >
            <Popup autoPan={false}>
              {text}
            </Popup>
          </Polyline>
        );
      }
    }

    setLines(lines);
  }

  const getTrucks = async () => {
    let trucks = [];

    await fetch('/vehicles/' + props.organizationId).then(data => {
      if (!data.vehicles) return;

      data.vehicles.forEach(truck => {
        if (truck['latitude'] != null) {
          const date = new Date(truck['time']);
          const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
            + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

          trucks.push(
            <Marker key={truck['vehicleId']}
              position={[truck['latitude'], truck['longitude']]}
              icon={new L.Icon({
                iconUrl: 'truck.gif',
                iconSize: [45, 36],
                iconAnchor: [23, 36],
                popupAnchor: [null, -36]
              })}>
              <Popup autoPan={false}>
                <span>
                  {truck['vehicleId']}
                </span>
                <br />
                <span>
                  Viimeisin aika: {time}
                </span>
              </Popup>
            </Marker>
          );
        }
      });

      setTrucksState(trucks);
    }).catch(error => {
      console.log(error);
    });
  }

  const changeDirection = (value, event) => {
    let dirs = directions.slice();

    if (event.target.checked) {
      dirs.push(value);
    }
    else {
      const index = dirs.findIndex(direction => direction === value);
      dirs.splice(index, 1);
    }

    setDirections(dirs);
  }

  const selectLine = (index) => {
    setSelectedLine(index);
  }

  function MapControl() {
    map = useMap();

    useMapEvent('click', (event) => {
      selectLine.bind(null, null);
    });

    const mapCenter = map.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng);
    const mapTiles = MapTiles(converted.x, converted.y);

    if (mapTilesUrl !== mapTiles.url) {
      setMapTilesUrl(mapTiles.url);
      setMapTilesAttribution(mapTiles.attribution);
      setMaxZoom(mapTiles.maxZoom);
    }
  }

  useEffect(() => {
    update(props);
  }, [directions, selectedLine, props.mapPaths])

  if (props.mapPosition && props.mapZoom) {
    position = props.mapPosition;
    zoom = props.mapZoom;
  }

  return (
    <div>
      <div className='center'>
        {'Suunnat: '}
        <label className='radio-container'>
          1
          <input id='direction1' type='checkbox' name='direction' value='1'
            onChange={changeDirection.bind(this, 1)}
            defaultChecked={directions.includes(1)} />
          <span className="checkmark"></span>
        </label>
        <label className='radio-container'>
          2
          <input id='direction2' type='checkbox' name='direction' value='2'
            onChange={changeDirection.bind(this, 2)}
            defaultChecked={directions.includes(2)} />
          <span className="checkmark"></span>
        </label>
      </div>
      <div style={{
        position: "fixed",
        left: 0,
        height: height,
        width: '100%'
      }}>
        <MapContainer id="map-area" center={position} zoom={zoom} maxZoom={19}>
          <MapControl />
          <TileLayer url={mapTilesUrl}
            attribution={mapTilesAttribution}
            maxZoom={19} />
          {props.site != null && props.site.get('coating_plant_laditude') != null ? (
            <Marker position={[props.site.get('coating_plant_laditude'), props.site.get('coating_plant_longitude')]}
              icon={new L.Icon({
                iconUrl: 'coating_plant.gif',
                iconSize: [33, 50],
                iconAnchor: [17, 50],
                popupAnchor: [null, -50]
              })}>
              <Popup>
                <span>{props.site.get('coating_plant_laditude')}, {props.site.get('coating_plant_longitude')}</span>
              </Popup>
            </Marker>
          ) : null}
          {props.yourLatitude != null ? (
            <Marker position={[props.yourLatitude, props.yourLongitude]}
              icon={new L.Icon({
                iconUrl: 'your_location.gif',
                iconSize: [18, 43],
                iconAnchor: [9, 43],
                popupAnchor: [null, -43]
              })}>
              <Popup>
                <span>{props.yourLatitude}, {props.yourLongitude}</span>
              </Popup>
            </Marker>
          ) : null}
          {trucks}
          {lines}
        </MapContainer>
      </div>
      {showedWarning ? 'Yhtä tai useampaa kuormaa ei voida näyttää sijainti tiedon virheellisyyden vuoksi.' : ''}
      {props.loading ?
        <div className='main loader' /> : null
      }
    </div>
  );
};